export const tilstandOptions = [
  { id: 7, name: 'Under normal' },
  { id: 6, name: 'Normal' },
  { id: 5, name: 'Bedre enn normal' },
];

export const tilstandDefaultId = 6;

export function getTilstandName(id) {
  return tilstandOptions.find((t) => t.id === id).name;
}

export function isInteger(text) {
  return /^\d+$/.test(text);
}

export function formatNumber(number) {
  return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
}

export function calculatePrice(questions, price) {
  let percentage;
  let alternative;
  let value = 0;

  for (let i = 0; i < questions.length; i++) {
    const question = questions[i];
    if (question.Alternatives.length === 1 && !question.checked) {
      alternative = question.Alternatives[0];
      switch (alternative.CalculationOption) {
        case 0:
          value += alternative.Value;
          break;
        case 1:
          percentage = (price * alternative.Value) / 100;
          value += percentage;
          break;
      }
    }

    if (question.Alternatives.length > 1 && question.selection) {
      const alternativeId = parseInt(question.selection);
      alternative = question.Alternatives.find(
        (a) => a.AnswerOptionId === alternativeId
      );

      percentage = (price * alternative.Value) / 100;
      value += percentage;
    }
  }
  return Math.ceil(value);
}

export function convertAnswers(questions) {
  let answers = [];
  let i;
  let q;
  let a;

  for (i = 0; i < questions.length; i++) {
    q = questions[i];
    a = 0;

    if (q.Alternatives.length === 1) {
      if (q.checked) {
        a = q.Alternatives[0].AnswerOptionId;
      }
    } else {
      a = q.selection || 0;
    }
    answers.push({ QuestionId: q.Id, AnswerId: a });
  }
  return answers;
}
