import loop from '../images/ring.png';
import { useContext } from 'react';
import { ReturnUrlContext } from '../contexts/ReturnUrlContext';

export default function Page3() {
  const returnUrl = useContext(ReturnUrlContext);

  return (
    <div className='mt-36'>
      <div className='grid grid-cols-1 gap-8 md:grid-cols-2'>
        <div className=''>
          <img
            src={loop}
            alt='Logo'
            className='mx-auto'
          />
        </div>
        <div className=''>
          <h3 className='mt-12 text-2xl font-bold'>
            {' '}
            Kontaktinformasjon er innsendt{' '}
          </h3>
          <div className='mt-2 mb-8'>
            Du vil motta bekreftelse på epost om innsendt kontaktskjema.
            Forespørselen vil bli sendt til rett forhandler etter postnummer.
            Når henvendelsen er mottatt vil du bli kontaktet av en forhandler.
          </div>
          <div className='text-center text-lg'>
            {returnUrl && <a href={returnUrl}>Tilbake til Polestar</a>}
          </div>
        </div>
      </div>
    </div>
  );
}
