import loop from '../images/ring.png';

import Button from './button';
import TextInput from './textinput';
import { useState } from 'react';

import { isInteger } from '../utils/util';
import AnswerPanel from './AnswerPanel.jsx';

export default function Page1({
  form,
  onChange,
  onSearchMarketplacePrices,
  questions,
  onAnswerChange,
  tilstand,
  onTilstandChange,
}) {
  const [validField, setValidField] = useState({
    regnr: false,
    kmstand: false,
  });
  const [isValid, setIsValid] = useState(false);

  function isRegNumber(str) {
    const regex = /^[a-zA-Z]{2}\d{5}$/;
    return regex.test(str);
  }

  function handleChange(event, isValid) {
    const valid = { ...validField, [event.target.name]: isValid };
    setValidField(valid);
    setIsValid(Object.values(valid).every((el) => el === true));
    onChange(event);
  }

  return (
    <div>
      <div className='grid grid-cols-1 gap-8 md:grid-cols-2'>
        <div className=''>
          <img
            src={loop}
            alt='Logo'
            className='mx-auto'
          />
          <div className='mt-5 text-2xl font-bold'>Selg din bil til oss</div>
          <div className='mb-8'>
            Vi gjør dette enkelt for deg, smidig og profesjonelt
          </div>
        </div>
        <div>
          <div className='mt-12 italic'>
            For best mulig estimat på markedspris, oppgi mest mulig informasjon
          </div>
          <div className='grid grid-cols-1 gap-x-2 sm:grid-cols-2'>
            <div>
              <TextInput
                label='Reg.nr'
                name='regnr'
                value={form.regnr}
                requireValue={true}
                validator={isRegNumber}
                onChange={handleChange}
              />
            </div>
            <div className=''>
              <TextInput
                label='Kilometerstand'
                name='kmstand'
                requireValue={true}
                value={form.kmstand}
                validator={isInteger}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className='my-8'>
            <AnswerPanel
              questions={questions}
              onAnswerChange={onAnswerChange}
              tilstand={tilstand}
              onTilstandChange={onTilstandChange}
            />
          </div>

          <div>
            <Button
              onClick={() => onSearchMarketplacePrices()}
              disabled={!isValid}
              label='Bekreft informasjon'
            />
          </div>
        </div>
      </div>
    </div>
  );
}
