import nextId from 'react-id-generator';

export default function CheckList({ data, name, onChange }) {
  return (
    <fieldset className="space-y-2">
      {data.map((item) => {
        const id = nextId();

        return (
          <div className="relative flex items-start" key={item.id}>
            <div className="flex h-5 items-center">
              <input
                id={id}
                name={name}
                type="checkbox"
                value={item.id}
                checked={item.checked}
                onChange={onChange}
                className="h-4 w-4 rounded border-gray-300 text-sentinel focus:ring-sentinel"
              />
            </div>
            <div className="ml-3 text-sm">
              <label htmlFor={id} className="font-medium text-gray-700">
                {item.text}
              </label>
            </div>
          </div>
        );
      })}
    </fieldset>
  );
}
