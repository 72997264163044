export const store = {
  questions: [],
  regNumber: '',
  mileage: '0',
  model: '',
  userPhone: '',
  userName: '',
  userEmail: '',
  zipCode: '',
};

export const configuration = {
  apiKey: '1AA5F9E2-7235-4ABC-9DDF-35814FD345B4',
  defaultReturnUrl: 'https://www.polestar.com/no/',
};
