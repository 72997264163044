import SelectMenu from './selectmenu';
import CheckList from './checklist';

import { tilstandOptions } from '../utils/util';

export default function AnswerPanel({
  questions,
  onAnswerChange,
  tilstand,
  onTilstandChange,
}) {
  return (
    <>
      <div className='mb-2 font-bold'>Informasjon om bilen din:</div>
      <div className='my-3'>
        <CheckList
          data={questions.map((el) => {
            return {
              id: el.Id.toString(),
              text: el.Name,
              checked: el.checked,
            };
          })}
          name='answers'
          onChange={onAnswerChange}
        />
      </div>

      <div className='my-5'>
        <SelectMenu
          data={tilstandOptions}
          label='Din vurdering av bilens tilstand'
          value={tilstand}
          onItemChange={onTilstandChange}
        />
      </div>
    </>
  );
}
