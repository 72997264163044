import agreeImage from '../images/agree.png';
import handshakeImage from '../images/handshake.png';
import addImage from '../images/addimage.png';

import Button from './button';
import TextInput from './textinput';
import { useState } from 'react';
import AreaInput from './AreaInput';

export default function Page2({
  sendContactData,
  form,
  onChange,
  onFileChange,
  images,
  isSending,
}) {
  const [validField, setValidField] = useState({
    name: false,
    zipCode: false,
    email: false,
    telephone: false,
  });
  const [isValid, setIsValid] = useState(false);

  function handleChange(event, isValid) {
    const valid = { ...validField, [event.target.name]: isValid };
    setValidField(valid);
    setIsValid(Object.values(valid).every((el) => el === true));
    onChange(event);
  }

  function isTextValidEmailAddress(text) {
    const re = /\S+@\S+\.\S+/;
    return re.test(text);
  }

  function isTextValidZipCode(text) {
    const re = /^[0-9]{4}$/;
    return re.test(text);
  }

  function isTextValidTelephone(text) {
    const re = /^[0-9]{8}$/;
    return re.test(text);
  }

  return (
    <div className='grid grid-cols-1 gap-8 md:grid-cols-2'>
      <div className=''>
        <div className='mt-5 text-2xl font-bold'>Kontaktinformasjon</div>

        <div className='grid grid-cols-1 md:grid-cols-3 gap-x-1'>
          <div className='md:col-span-2'>
            <TextInput
              label='Navn'
              name='name'
              value={form.name}
              requireValue={true}
              onChange={handleChange}
            />
          </div>
          <div className=''>
            <TextInput
              label='Postnummer'
              name='zipCode'
              value={form.zipCode}
              requireValue={true}
              validator={isTextValidZipCode}
              onChange={handleChange}
            />
          </div>
        </div>
        <TextInput
          label='Epost'
          name='email'
          value={form.email}
          requireValue={true}
          validator={isTextValidEmailAddress}
          onChange={handleChange}
        />

        <TextInput
          label='Telefon'
          name='telephone'
          value={form.telephone}
          requireValue={true}
          validator={isTextValidTelephone}
          onChange={handleChange}
        />

        <AreaInput
          label='Kommentar'
          name='comment'
          value={form.comment}
          onChange={handleChange}
        />

        <div className='mt-8'>
          <div className='font-medium mb-2'>Last opp bilder av bilen</div>
          <div className='grid grid-cols-1 md:grid-cols-3 gap-4 grid-flow-row'>
            <label
              className='cursor-pointer focus:outline-none hover:bg-gray-100'
              htmlFor='image_upload'
            >
              <img
                src={addImage}
                alt='Legg til bilde'
              />
            </label>
            {images.length > 0
              ? images.map((image, idx) => {
                  return (
                    <img
                      className='max-w-[143]'
                      key={idx}
                      src={image}
                      alt=''
                    />
                  );
                })
              : null}
          </div>
        </div>
        <input
          type='file'
          id='image_upload'
          className='opacity-0'
          onChange={onFileChange}
          accept='image/*'
          multiple
        />

        <div className='mt-8'>
          <Button
            disabled={!isValid || isSending}
            label='Send'
            onClick={sendContactData}
            largeFont={true}
          />
        </div>
        <div className='text-xs text-gray-500 mt-5'>
          Ved å sende denne henvendelsen godtar du våre retningslinjer for
          personvern. Vi er nødt til å samle inn og behandle dine opplysninger
          for å følge opp din henvendelse.
        </div>
      </div>
      <div className='pt-28'>
        <div className='flex'>
          <img
            src={agreeImage}
            alt='Logo'
            className='mx-auto'
          />
          <img
            src={handshakeImage}
            alt='Logo'
            className='mx-auto'
          />
        </div>
        <h3 className='mt-10 text-2xl font-bold'>Dette skjer videre nå</h3>
        <div className='mb-8'>
          Takk for at du tok deg tid til å fylle ut skjemaet. For at vi skal
          kunne gi deg en konkret innbyttepris må vi ta en inspeksjon av bilen
          for å avdekke mulige avvik før vi tar inn bilen. Dette gjør vi for å
          unngå forsinkelser og misforståelser ved innlevering. Du vil bli
          kontaktet av nærmeste Polestar partner for å avtale en tid for
          inspeksjon. Vi minner om at prisen som du har fått gjennom dette
          verktøyet er en indikasjon på hva vi tror bilen er verdt på det åpne
          markedet for øyeblikket. Ved innbytte så vil vi tilby en pris som vil
          være lavere, dette fordi vi må kalkulere våre kostnader i forbindelse
          med salget av ditt kjøretøy.
        </div>
      </div>
    </div>
  );
}
