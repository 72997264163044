export const priceHelp = {
  Make: 'Mitsubishi',
  Model: 'Outlander',
  Year: 2008,
  CountAllCars: 0,
  MinPrice: 61353,
  Price: 69000,
  MaxPrice: 76647,
  AvgPrice: 69000,
  Mileage: 0,
  AvgSellDays: 0,
  SoldCarCount: 0,
  ChangeSoldCarCount: 0,
  ChangeSoldCarPrice: 0,
};

export const questions = [
  {
    Id: 11,
    Name: '2 sett med fungerende nøkler',
    Price: 0,
    Alternatives: [
      {
        AnswerOptionId: 1,
        AlternativeText: '\n',
        CalculationOption: 1,
        Value: -2.5,
        MinRange: 0.0,
        MaxRange: 0.0,
      },
    ],
  },
  {
    Id: 12,
    Name: '2 sett hjul m salgbar mønsterdybde og kvalitet',
    Price: 0,
    Alternatives: [
      {
        AnswerOptionId: 2,
        AlternativeText: '',
        CalculationOption: 1,
        Value: -5.0,
        MinRange: 0.0,
        MaxRange: 0.0,
      },
    ],
  },
  {
    Id: 13,
    Name: 'Fulgt produsentens service- og vedlikeholdsprogram',
    Price: 0,
    Alternatives: [
      {
        AnswerOptionId: 3,
        AlternativeText: '',
        CalculationOption: 1,
        Value: -7.5,
        MinRange: 0.0,
        MaxRange: 0.0,
      },
    ],
  },
  {
    Id: 14,
    Name: 'Ingen skader ut over normal slitasje',
    Price: 0,
    Alternatives: [
      {
        AnswerOptionId: 4,
        AlternativeText: '',
        CalculationOption: 1,
        Value: -7.5,
        MinRange: 0.0,
        MaxRange: 0.0,
      },
    ],
  },
  {
    Id: 15,
    Name: 'Hva er din vurdering av bilens tilstand',
    Price: 0,
    Alternatives: [
      {
        AnswerOptionId: 5,
        AlternativeText: 'Bedre enn normalt',
        CalculationOption: 1,
        Value: 2.5,
        MinRange: 0.0,
        MaxRange: 0.0,
      },
      {
        AnswerOptionId: 6,
        AlternativeText: 'Normal',
        CalculationOption: 0,
        Value: 0.0,
        MinRange: 0.0,
        MaxRange: 0.0,
      },
      {
        AnswerOptionId: 7,
        AlternativeText: 'Under normal',
        CalculationOption: 1,
        Value: -5.0,
        MinRange: 0.0,
        MaxRange: 0.0,
      },
    ],
  },
];
