import Button from './button';
import { InfoPoster } from './infoposter';
import { formatNumber } from '../utils/util';
import AnswerPanel from './AnswerPanel';

export default function PageMiss({
  questions,
  nextPage,
  resetSearch,
  onAnswerChange,
  tilstand,
  onTilstandChange,
  modelText,
  form,
}) {
  return (
    <div>
      <div className='grid grid-cols-1 gap-8 md:grid-cols-2'>
        <div>
          <InfoPoster />
        </div>
        <div>
          <div className='my-2 text-2xl font-bold text-gray-600'>
            {modelText}
          </div>

          <div className='mt-3 mb-5 text-sm'>
            <span className='text-gray-500'>Reg. nr:</span>{' '}
            {form.regnr.toUpperCase()}
            <br />
            <span className='text-gray-500'>Km. stand:</span>{' '}
            {formatNumber(form.kmstand)}
            <br />
          </div>

          <div>
            <Button
              onClick={resetSearch}
              label='Endre bil'
            />
          </div>

          <div className='my-8'>
            <AnswerPanel
              questions={questions}
              onAnswerChange={onAnswerChange}
              tilstand={tilstand}
              onTilstandChange={onTilstandChange}
            />
          </div>

          <div className='text-lg font-medium mb-1'>Bilen din er spesiell</div>

          <div className='text-gray-500'>
            Vi har dessverre ikke tilstrekkelig datagrunnlag for bilen din. For
            å få best mulig estimat anbefaler vi at du fyller ut
            kontaktskjemaet. Vi vil deretter foreta en manuell håntering og ta
            kontakt med deg.
          </div>

          <div className='my-5'>
            <Button
              onClick={nextPage}
              label='Kontakt meg med et konkret tilbud'
              largeFont={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
