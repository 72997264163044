import CarImage from '../images/car.png';
import CashImage from '../images/cash.png';

export function InfoPoster() {
  return (
    <div>
      <div className='flex flex-col-2 justify-evenly my-4'>
        <img
          src={CarImage}
          alt='Bil'
        />
        <img
          src={CashImage}
          alt='Penger'
        />
      </div>
      <div className='mt-8 text-2xl font-bold'>
        Dette påvirker innbytteprisen på bilen din
      </div>
      <div className='mb-8'>
        <p className='my-3'>
          Det er mange faktorer som må tas i betraktning når vi skal finne en
          riktig pris for en bruktbil. Alle bruktbiler er forskjellige og noen
          er mer ettertraktet enn andre slik at det varierer veldig på hvor lang
          tid det tar å selge en bruktbil. Utstyrsnivå, farge, drivlinje,
          tilstand på bil og dekk er viktige faktorer som vil bestemme hvor
          attraktiv bilen er.
        </p>
      </div>
    </div>
  );
}
