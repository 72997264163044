import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import ErrorAlert from './components/erroralert';
import Page1 from './components/page1';
import Page2 from './components/page2';
import Page3 from './components/page3';
import PageHit from './components/page_hit';
import PageMiss from './components/page_miss';

import { getPrice, getQuestionList, postFormData } from './API';
import { configuration, store } from './store';
import {
  calculatePrice,
  convertAnswers,
  getTilstandName,
  tilstandDefaultId,
} from './utils/util';

import { priceHelp } from './data/testdata';
import { ReturnUrlContext } from './contexts/ReturnUrlContext';

export function App() {
  const [form, setForm] = useState({
    regnr: '',
    kmstand: '',
    name: '',
    zipCode: '',
    email: '',
    telephone: '',
    comment: '',
  });

  const [selectedPage, setSelectedPage] = useState(0);
  const [fileList, setFileList] = useState([]);

  const [tilstand, setTilstand] = useState({
    id: tilstandDefaultId,
    name: getTilstandName(tilstandDefaultId),
  });
  const [price, setPrice] = useState({ min: 0, median: 0, max: 0 });
  const [apiPrice, setApiPrice] = useState({ min: 0, median: 0, max: 0 });

  const [questions, setQuestions] = useState([]);

  const [showErrorAlert, setShowErrorAlert] = useState(false);

  const [carData, setCarData] = useState({
    year: 0,
    make: '',
    model: '',
  });
  const [returnUrl, setReturnUrl] = useState(null);
  const [modelText, setModelText] = useState('');
  const [uid, setUid] = useState(uuidv4());
  const [isSending, setIsSending] = useState(false);

  function setupTestData() {
    const prices = {
      min: priceHelp.MinPrice,
      median: priceHelp.AvgPrice,
      max: priceHelp.MaxPrice,
    };
    setPrice(prices);
    setCarData({
      year: priceHelp.Year,
      make: priceHelp.Make,
      model: priceHelp.Model,
    });

    const model = priceHelp.Make + ' ' + priceHelp.Model + ' ' + priceHelp.Year;
    setModelText(model);
  }

  useEffect(() => {
    getQuestionList(configuration.apiKey).then((result) => {
      setQuestions(
        result.map((el) => {
          return { ...el, checked: false, selection: el.Id === 15 ? 6 : null };
        })
      );
    });

    const url = getParameter(window.location.search, 'returnurl');
    setReturnUrl(url ?? configuration.defaultReturnUrl);
  }, []);

  function getParameter(queryString, searchKey) {
    const urlParams = new URLSearchParams(queryString);

    for (const [key, value] of urlParams) {
      if (key.toLowerCase() === searchKey) {
        return value;
      }
    }
    return null;
  }

  function handleTextChange(input) {
    setForm({ ...form, [input.target.name]: input.target.value });
  }

  function handleAnswerChange(input) {
    const tmp = questions.map((el) => {
      return {
        ...el,
        checked:
          el.Id.toString() === input.target.value
            ? input.target.checked
            : el.checked,
      };
    });
    setPrice(calculatePrices(tmp, apiPrice));
    setQuestions(tmp);
  }

  function handleTilstandChange(item) {
    const tmp = questions.map((el) => {
      return { ...el, selection: el.Id === 15 ? item.id : null };
    });

    setPrice(calculatePrices(tmp, apiPrice));
    setQuestions(tmp);
    setTilstand(item);
  }

  function handleFileChange(event) {
    const lst = [...fileList];

    for (let i = 0; i < event.target.files.length; i++) {
      lst.push(event.target.files[i]);
    }
    setFileList(lst);
  }

  function submitData(contactData, result) {
    const formData = new FormData();

    for (let i = 0; i < fileList.length; i++) {
      let file = fileList[i];
      formData.append('image-' + i, file, file.name);
    }

    contactData.values = contactData.values || {};
    contactData.values.customer_comment = form.comment;

    if (returnUrl) {
      contactData.values.linkback = returnUrl;
    }

    formData.append('contactData', JSON.stringify(contactData));

    postFormData(configuration.apiKey, formData)
      .then(() => {
        result(true);
      })
      .catch((error) => {
        console.error('postFormData error', error);
        result(false);
      });
  }

  function handlePage(pageIndex) {
    setSelectedPage(pageIndex);
  }

  function sendContactData() {
    setIsSending(true);

    const makeModel =
      carData.make !== ''
        ? carData.make + ' ' + carData.model + ' ' + carData.year
        : '';

    const answers = convertAnswers(questions);

    let contactData = {
      regNr: form.regnr,
      year: carData.year || 0,
      mileage: form.kmstand || 0,
      modelVariant: makeModel,
      mileageAverage: 0,
      priceHigh: apiPrice.max || 0,
      priceMedian: apiPrice.median || 0,
      priceLow: apiPrice.min || 0,
      correctedPriceHigh: price.max,
      correctedPriceMedian: price.median,
      correctedPriceLow: price.min,
      contactName: form.name,
      contactEmail: form.email,
      contactMobile: form.telephone,
      zipCode: form.zipCode,
      contactAddress: '',
      answers: answers,
      uid: uid,
    };
    submitData(contactData, (success) => {
      if (success) {
        setSelectedPage(4);
      } else {
        setIsSending(false);
        setShowErrorAlert(true);
      }
    });
  }

  function setMissingPrices() {
    const prices = {
      min: 0,
      median: 0,
      max: 0,
    };
    setApiPrice(prices);
    setPrice(prices);
  }

  function resetSearch() {
    setForm({
      regnr: '',
      kmstand: '',
      name: '',
      zipCode: '',
      email: '',
      telephone: '',
    });
    setCarData({
      year: 0,
      make: '',
      model: '',
    });
    store.model = '';
    setModelText('');
    setTilstand({
      id: tilstandDefaultId,
      name: getTilstandName(tilstandDefaultId),
    });

    const tmp = questions.map((el) => {
      return {
        ...el,
        checked: false,
      };
    });
    setQuestions(tmp);

    setFileList([]);

    handlePage(0);
  }

  function handlePriceSearch() {
    getPrice(configuration.apiKey, form.regnr, form.kmstand, uid).then(
      (result) => {
        store.carData = result;

        if (result.Make) {
          store.model = result.Make + ' ' + result.Model + ' ' + result.Year;
          setModelText(store.model);
          setCarData({
            year: result.Year,
            make: result.Make,
            model: result.Model,
          });

          if (result.Price) {
            const price = {
              min: result.MinPrice,
              median: result.Price,
              max: result.MaxPrice,
            };
            setApiPrice(price);
            setPrice(calculatePrices(questions, price));

            setSelectedPage(1);
          } else {
            setMissingPrices();
            setSelectedPage(2);
          }
        } else {
          store.model = 'Ukjent merke/modell';
          setModelText(store.model);
          setMissingPrices();

          setSelectedPage(2);
        }
      }
    );
  }

  const calculatePrices = (questions, prices) => {
    return {
      min: prices.min + calculatePrice(questions, prices.min),
      median: prices.median + calculatePrice(questions, prices.median),
      max: prices.max + calculatePrice(questions, prices.max),
    };
  };

  const getPreviewUrls = () => {
    let urls = [];

    for (let i = 0; i < fileList.length; i++) {
      let file = fileList[i];
      urls.push(URL.createObjectURL(file));
    }
    return urls;
  };

  let page = (
    <Page1
      form={form}
      onChange={handleTextChange}
      onSearchMarketplacePrices={handlePriceSearch}
      questions={questions.filter((el) => el.Id !== 15)}
      onAnswerChange={handleAnswerChange}
      tilstand={tilstand}
      onTilstandChange={handleTilstandChange}
    />
  );

  if (selectedPage === 1) {
    page = (
      <PageHit
        nextPage={() => handlePage(3)}
        resetSearch={resetSearch}
        price={price}
        questions={questions.filter((el) => el.Id !== 15)}
        onAnswerChange={handleAnswerChange}
        modelText={modelText}
        tilstand={tilstand}
        onTilstandChange={handleTilstandChange}
        form={form}
      />
    );
  }

  if (selectedPage === 2) {
    page = (
      <PageMiss
        nextPage={() => handlePage(3)}
        resetSearch={resetSearch}
        questions={questions.filter((el) => el.Id !== 15)}
        onAnswerChange={handleAnswerChange}
        tilstand={tilstand}
        onTilstandChange={handleTilstandChange}
        modelText={modelText}
        form={form}
      />
    );
  }

  if (selectedPage === 3) {
    page = (
      <Page2
        sendContactData={sendContactData}
        form={form}
        onChange={handleTextChange}
        onFileChange={handleFileChange}
        images={getPreviewUrls()}
        isSending={isSending}
      />
    );
  }

  if (selectedPage === 4) {
    page = <Page3 />;
  }

  return (
    <div>
      <ReturnUrlContext.Provider value={returnUrl}>
        <ErrorAlert
          open={showErrorAlert}
          setOpen={setShowErrorAlert}
        />
        {page}
      </ReturnUrlContext.Provider>
    </div>
  );
}
